<template>
    <b-modal id="apply" centered :title="$t('apply_job.title')" ref="modal" title-class="font-22"
             hide-footer @shown="create">

        <form id="form" method="POST" @submit.prevent="handleSubmit">
            <div class="form-group mt-3 mb-0">
                <label class="pb-2"> {{$t('profil_user.settings.full_name')}} :</label>
                <b-form-input type="text"  v-model="postuler.full_name"
                              :class="{ 'is-invalid': typesubmit && $v.postuler.full_name.$error }"></b-form-input>
                <div v-if="typesubmit && $v.postuler.full_name.$error" class="invalid-feedback">
                    <span v-if="!$v.postuler.full_name.required"> {{$t('validation.required')}}</span>
                </div>
            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2"> {{$t('profil_user.settings.email')}} :</label>
                <b-form-input type="text"  v-model="postuler.email"
                              :class="{ 'is-invalid': typesubmit && $v.postuler.email.$error }"></b-form-input>
                <div v-if="typesubmit && $v.postuler.email.$error" class="invalid-feedback">
                    <span v-if="!$v.postuler.email.required"> {{$t('validation.required')}}</span>
                    <span  v-else-if="!$v.postuler.email.email">{{$t('validation.email')}}</span>
                </div>
            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2"> {{$t('profil_user.settings.tele')}} :</label>
                <b-form-input type="text" v-model="postuler.phone_number"></b-form-input>
            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2">{{$t('apply_job.other')}} :</label>
                <b-form-textarea rows="5" v-model="postuler.description" style="resize: none"></b-form-textarea>
            </div>
            <div class="form-group mt-3 mb-0">
                <label class="pb-2"> {{$t('apply_job.cv')}} :</label>
                <b-form-input type="text"  v-model="postuler.link_cv"
                              :class="{ 'is-invalid': typesubmit && $v.postuler.link_cv.$error }"></b-form-input>
                <div v-if="typesubmit && $v.postuler.link_cv.$error" class="invalid-feedback">
                    <span v-if="!$v.postuler.link_cv.required"> {{$t('validation.required')}}</span>
                    <span  v-else-if="!$v.postuler.link_cv.url">{{$t('validation.url')}}</span>
                </div>
            </div>
            <div class="form-group mt-3 mb-0 ">
                <b-form-checkbox  v-model="accept" :class="{ 'is-invalid': typesubmit && $v.accept.$error }">
                    {{$t('apply_job.accept')}}
                </b-form-checkbox>
                <div v-if="typesubmit && $v.accept.$error" class="invalid-feedback">
                    <span v-if="!$v.accept.sameAs"> {{$t('validation.accept')}}</span>
                </div>
            </div>
            <input type="submit" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>
        </form>
    </b-modal>

</template>

<script>

    import helper from "@/assets/helpers/helper";
    import {email, required,sameAs,url} from "vuelidate/lib/validators";
    export default {
        name: "Apply",
        props:['id'],
        data() {
            return {
                typesubmit:false,
                 accept:false,
                postuler: {
                    full_name:this.$store.state.user.full_name,
                    email:this.$store.state.user.email,
                    phone_number:this.$store.state.user.phone,
                    description:'',
                    link_cv:''
    
                },
            }
        },
        validations: {
             accept:  {sameAs: sameAs( () => true )},
            postuler:{
                email: {required,email},
                full_name: {required},
                link_cv: {required,url},

            },
        },
        methods: {
            create() {
                this.typesubmit = false
                this.accept = false
                this.postuler = {
                    full_name:this.$store.state.user.full_name,
                    email:this.$store.state.user.email,
                    phone_number:this.$store.state.user.phone,
                    description:'',
                    link_cv:''
                    
                }
            },
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.PostulerJob();

            },
            async  PostulerJob() {
                let re= await helper.postReturnData('careers/'+this.id+'/apply', this.postuler)
                if(re){
                    this.$emit("apply-success");
                    this.$refs['modal'].hide()
                }

            },

        }
    }
</script>

