<script>

    import appConfig from "@/app.config";
    import PageHeader from "@/components/structure/page-header";
    import Apply from "../../components/popups/postuler";
    // import helper from "@/assets/helpers/helper";

    /**
     * Projects-grid component
     */
    export default {
        page: {
            title: "Careers",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'Careers',
        components: {Apply, PageHeader},
        data() {
            return {
                jobs: [],
                load: false,
                title: "Careers",
                careers: [],
                job_id: ''
            };
        },
        created() {
            this.loadJobs()
        },
        methods: {
            showPostul(id) {
                this.job_id = id
                this.$root.$emit('bv::show::modal', 'apply')
            },
            refreshJob() {
                let index = this.careers.findIndex(element => element.id == this.job_id)
                console.log(index)
                this.careers[index].is_applied = 1
                this.careers[index].status = 'pending'
            },
            async loadJobs() {
                this.$http.get('careers').then(({data}) => {
                    this.careers = data;
                    this.load = true

                });
                // this.careers = await helper.getAll('careers');

            },
        }
    };
</script>

<template>
    <main>
        <PageHeader :title="$t('menuitems.careers.text')"/>
        <div class="row mt-3">
            <div class="col-xl-9">
                <div v-if="load">
                    <h6 class="text-muted m-3" v-if="careers.length == 0">{{$t('validation.no_data')}}</h6>
                    <div role="tablist" v-if="careers.length">
                        <b-card no-body class="mb-1 " v-for="(career,index) in careers" :key="career.id">
                            <b-card-header header-tag="header" role="tab">
                                <div class="row py-auto px-2">
                                    <h6 class="my-auto"><router-link class="text-primary link"
                                                              :to="{name:'ProfilCompany',params:{id:career.company.id}}">{{career.company.name}}</router-link>
                                        | {{career.title}}</h6>
                                    <button class="btn btn-link ml-auto" type="button" v-b-toggle="'area'+index"
                                            style="cursor: pointer" aria-expanded="true">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </b-card-header>
                            <b-collapse :id="'area'+index" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>
                                        <div class="row  my-2" v-if="career.is_applied">
                                            <span class="badge badge-pill ml-auto font-size-12 badge-soft-primary p-2">{{career.status}}</span>
                                        </div>
                                        <p>{{career.description}}</p>
                                        <div class="row my-2">
                                            <button class="btn btn-secondary ml-auto " disabled
                                                    v-if="career.is_applied">{{$t('company.postuler')}}
                                            </button>
                                            <button class="btn btn-primary ml-auto " v-else
                                                    @click="showPostul(career.id)">{{$t('company.postuler')}}
                                            </button>
                                        </div>
                                    </b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>

                </div>
                <div v-else>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                    <b-skeleton width="100%" class="mb-2" height="2.5rem"></b-skeleton>
                </div>

            </div>
        </div>
            <apply :id="job_id" v-on:apply-success="refreshJob"/>
            <!-- end ro w -->
        </main>
</template>
<style scoped>


</style>
